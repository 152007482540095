
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
// 全局样式
import Element from 'element-ui'

Vue.config.productionTip = false
Vue.use(Element)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
