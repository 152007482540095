
import Vue from "vue";
import router from "vue-router";
Vue.use(router);

const constantRoutes = [
  
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/home")
  },
  {
    path: "/index2",
    name: "index2",
    component: () => import("@/views/home2")
  },
  
];

// 防止连续点击多次路由报错
const routerPush = router.prototype.push;
router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

const Router = new router({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});

// 守卫
Router.beforeEach((to, form, next) => {
  next();
});

export default Router;
